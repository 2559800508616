.row {
  justify-content: space-between;
  width: 100%;

  .button {
    text-transform: uppercase;
    padding: 0;

    svg {
      font-size: 1.25rem;
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;