.row {
  display: flex;
  cursor: pointer;
  border-radius: 6px;

  &.active {
    background-color: var(--color-tag-light-blue);
  }

  &.disabled {
    pointer-events: none;
  }

  .image {
    width: 58px;
    height: 46px;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid var(--color-line-table);
    margin-right: 1em;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .textBox {
    display: flex;
    flex-direction: column;

    .name {
      font-weight: 500;
      color: var(--color-dark);
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;