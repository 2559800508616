.checkboxWrapper {
  align-items: flex-start !important;

  span {
    &:nth-child(2) {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;