@import '/src/__variables.less';

.layout {
  height: 100vh;
  min-height: 450px;
  display: flex;
  width: 100vw;
  overflow: hidden;

  .container {
    flex: 1 1 auto;
    background: var(--color-white);
    display: flex;
    flex-direction: column;

    .content {
      padding: 32px;
      flex: 1 1 auto;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.header {
  border-bottom: 1px solid var(--color-border);

  .headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px 32px;
    min-height: 80px;
    gap: 20px;
  }

  .logoBox {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 12px 0 var(--color-box-shadow);
  }
}

.userBox {
  display: flex;
  align-items: center;
  gap: 12px;

  .userName {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .userAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
}

.siderWrapper {
  height: 100%;
  flex: 0 0 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
  width: 250px !important;

  .siderContent {
    height: 100%;

    .sider {
      height: 100%;
      display: grid;
      grid-template-rows: 1fr auto;
      background: var(--color-light-light-gray);
      position: relative;
      padding: 95px 35px 35px;

      .logo {
        position: absolute;
        top: 20px;
        left: 18px;
        width: 185px;
        height: 41px;
      }

      .logout {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        width: 199px;
        padding: 0;
        box-shadow: none;
        color: var(--color-gray);

        &:hover {
          color: var(--color-dark-blue);
        }

        span {
          margin-left: 10px;
        }
      }

      .ant-menu-item-icon {
        color: var(--color-white);
      }
    }

  }
}

.fullWidth {
  max-width: 100%;
}

.hamburger {
  position: relative;
  cursor: pointer;
  height: 20px;
  width: 25px;

  .line {
    position: absolute;
    width: 25px;
    height: 3px;
    border-radius: 4px;
    background: var(--color-dark);
    transition: .3s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 8px;
    }
    &:nth-child(3) {
      top: 16px;
    }
  }

  &.checked {
    .line {
      &:nth-child(1) {
        top: 10px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        top: 10px;
        transform: rotate(-45deg);
      }
    }
  }
}

@media screen and (max-width: @max-width-xs) {
  .layout {
    flex-direction: column !important;
    overflow: auto;

    .container {
      padding-top: 60px;
      .content {
        padding: 20px;
        overflow: hidden;
      }
    }
  }

  .siderWrapper {
    background: var(--color-white) !important;
    position: fixed !important;
    top: 0;
    z-index: 100;
    flex: 0 0 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    height: fit-content;

    .siderRow {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px;
      box-shadow: 0 4px 12px 0 var(--color-box-shadow);
    }

    .menuWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      bottom: calc(100% - 60px);
      z-index: 100;
      transition: .3s ease-in-out;
      background: var(--color-light-light-gray);

      .menuButton {
        padding-inline: 20px;
      }
    }

    .menuContent {
      padding: 0 20px;
    }

    .opened {
      height: 100vh;

      .menuWrapper {
        flex: 1 1 auto;
        bottom: 0;
      }
    }
  }

  .userBox {
    justify-self: flex-end;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    padding: 20px;

    .userName {
      order: 2;
    }

    .userAvatar {
      order: 1;
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;