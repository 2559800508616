.section {
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 0.5s ease-out;

  &.hidden {
    grid-template-rows: 0fr;
  }

  .contentWrapper {
    overflow: hidden;

    .content {
      padding-top: 32px;
    }
  }
}
@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;