.imageBox {
  overflow: hidden;

  &.rect {
    border-radius: 4px;
  }

  &.round {
    border-radius: 50%;
  }
}

.uploadButton {
  text-transform: uppercase;
  padding: 0 !important;
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;