.main-content-container {
  width: 60%;
  margin: 70px 0 30px 0;
  margin: 25px;
  background-color: var(--color-border);
  width: 50%;
  padding: 25px;
}

.conclusion-text {
  font-size: 26px;
  line-height: 28px;
  font-weight: 600;
  margin: 25px;
}

.paragraph-text {
  padding: 20px;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-gray);
}

.actions-container {
  display: flex;
  justify-content: space-between;
}

.textarea-edit {
  font-size: 16px;
  line-height: 20px;
  margin: 20px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.inputWrapper {
  margin: 20px;
}
@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;