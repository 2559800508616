.row {
  position: relative;
  min-height: 120px;
  display: flex;
  flex-direction: column;

  .days {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .day {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      .dayLine {
        width: 1px;
        background: var(--color-border);
        height: calc(100% - 1.2rem);
      }

      .dayNumber {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        line-height: 1rem;
        white-space: nowrap;
        color: var(--color-gray);
      }
    }
  }

  .events {
    position: relative;

    .simpleEvent {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      transform: translateY(-50%);

      .eventLabel {
        height: 1.2rem;

        .label {
          font-size: 0.875rem;
          font-weight: 500;
          color: var(--color-gray);
          cursor: default;
          white-space: nowrap;
        }
      }

      .bar {
        flex: 0 0 8px;
        border-radius: 4px;
        width: 100%;
      }
    }

    .advancedEvent {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .content {
        background: var(--color-white);
        box-shadow: 0 4px 12px 0 var(--color-box-shadow);
        padding: 4px;
      }

      .bar {
        min-height: 100%;
        width: 2px;
        border-radius: 2px;
      }
    }
  }
}

.closeOfEscrow {
  color: var(--color-gray) !important;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  position: absolute;
  margin-top: 25px;
}
@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;