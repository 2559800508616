.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  padding: 30px 10px;

  .message {
    color: var(--color-gray);
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;