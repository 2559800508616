.arrowWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: var(--color-white-8);
}

.activitiesList {
  position: relative;

  &:hover {
    .arrowWrapper {
      display: flex;
    }
  }
}

.arrow {
  font-size: 1.25rem !important;
  border: 1px solid var(--color-dark) !important;
  color: var(--color-dark) !important;
  border-radius: 50% !important;
  overflow: hidden !important;
  cursor: pointer !important;
  background: var(--color-white) !important;
  min-height: 1.5rem !important;
  min-width: 1.5rem !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    border: 1px solid var(--color-gray) !important;
    color: var(--color-gray) !important;
    cursor: default !important;
  }
}

.arrowLeft {
  left: 0;
  box-shadow: 2px 0 2px 0 var(--color-box-shadow);
}

.arrowRight {
  right: 0;
  box-shadow: -2px 0 2px 0 var(--color-box-shadow);
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;