.card-container {
    width: 95%;
    display: flex;
    flex-direction: row;
  }
  
  .card-image {
    width: 45%;
  }
  
  .card-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .card-details {
    margin-left: 10px;
    width: 45%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .category-text {
    color: var(--color-gray);
    display: block;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .header-text {
    display: block;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .header-input {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 15px;
    padding: 4px 11px; 
    border-radius: 2px; 
  }
  
  .details-text {
    color: var(--color-gray);
    font-size: 14px;
  }
  
  .actions-container {
    display: flex;
    justify-content: space-between;
  }
  
  .button {
    margin: 6px;
    border-radius: 7px;
  }
  
  @media screen and (max-width: 992px) {
    .card-container {
      flex-direction: column;
      align-items: center;
    }
  
    .card-image, .card-details {
      width: 100%;
    }
  
    .card-details {
      margin-left: 0;
      margin-top: 10px;
    }
  
    .header-text {
      font-size: 24px;
      line-height: 32px;
    }
  
    .category-text, .details-text {
      font-size: 13px;
      line-height: 18px;
    }
  }
  
@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;