.row {
  padding: 20px;
  border: 1px solid var(--color-border);
  border-radius: 8px;

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .price {
      color: var(--color-dark-blue);
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;