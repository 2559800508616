.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .textRow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .price {
      font-size: 2.5rem;
      margin-left: auto;
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;