.option {
  border: 1px solid var(--color-border);
  border-radius: 8px;
  overflow: hidden;

  .headerRow {
    background: var(--color-hover-gray);
    display: flex;
    gap: 10px;
    padding: 16px 20px;
    flex-wrap: wrap;

    .title {
      font-weight: 500;
      letter-spacing: 0.02em;
      color: var(--color-gray);
      text-transform: uppercase;
    }

    .subtitle {
      font-size: 0.625rem;
      color: var(--color-gray);
    }
  }
  .row {
    padding: 16px 20px;

    .cellText {
      color: var(--color-dark);
      white-space: nowrap;
      text-align: center;
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;