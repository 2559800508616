.footer {
  display: flex;
  align-items: flex-end;
  width: 100%;

  .pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    font-size: 0.875rem;

    svg {
      font-size: 1rem;
      color: var(--color-gray)
    }
  }

  .buttonWrapper {
    margin-left: auto;
  }
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .price {
    font-size: 2.5rem;
    margin-left: auto;
  }
}


@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;