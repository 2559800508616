.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 32px;

  .name {
    font-size: 1.25rem;
  }

  .features {
    flex: 1 1 auto;
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;