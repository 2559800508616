@import '/src/__variables.less';


.videoEmbed {
    max-width: 350px;
    width: 100%;
    margin-right: 100px;
    @media (max-width: @max-width-xs) {
        margin-right: 0;
    }
}

.videoEmbed iframe {
    display: initial; 
  }

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;