.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr min-content;

  .container {
    height: 100%;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-self: center;
    justify-content: center;
  }

  .stepper {
    justify-self: center;
  }
}

.textBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .title {
    margin: 0;
    color: var(--color-dark);
    user-select: none;
  }

  .underline {
    color: var(--color-blue);
    text-decoration: underline;
  }

  .text {
    font-size: 1rem;
    color: var(--color-gray);
    text-align: center;
    user-select: none;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .price {
    color: var(--color-dark-blue);
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;