@import '/src/__variables.less';

.textHeaderTool {
    line-height: 30px;
    font-size: 16px;
    font-weight: 600;
}

.tooltipContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.newsCard {
    height: 350px;
    border: 1px solid var(--color-border);
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.02);
        overflow: hidden;
        background-color: var(--color-border);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    }
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
}

.imageStyle {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: fill;
}

.textContainer {
    padding: 0px 10px;
    margin-top: 8px;
    width: 100%
}

.categoryText {
    color: var(--color-gray) !important;
    display: block;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.headerText {
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
    margin: 5px 0px;
}

.textBody {
    color: var(--color-gray) !important;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.dateAndLinkWrapper {
    margin-top: 0px
}

.dateAndLink {
    display: flex;
    align-items: center
}

.date {
    color: var(--color-gray) !important;
    font-size: 11px;
}

.link {
    color: var(--color-gray) !important;
    font-size: 11px;
}

.textBodyTool {
    line-height: 20px;
    font-size: 15px;
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;