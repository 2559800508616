.videoContainer {
    width: 50%;
    height: 100%;
}

.videoContainer iframe {
    display: initial;
  }

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    gap: 10px;
}

.editContainer {
    width: 50%;
}

.input {
    margin: 10px;
}

.button {
    margin: 10px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: center;
    align-items: center;
}
@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;