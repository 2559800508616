.textBox {
  display: flex;
  flex-direction: column;

  .label {
    font-size: 0.875rem;
    color: var(--color-dark);
  }
  .description {
    font-size: 0.875rem;
    color: var(--color-gray);
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;