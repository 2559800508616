@import '/src/__variables.less';

.wrapper {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, var(--color-white) 50%, var(--color-tag-light-blue) 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .container {
    max-height: @max-height-lg;

    .content {
      width: 100%;
      height: 100%;
      position: relative;
      flex: 1 1 50%;
      padding: 16px;

      .logo {
        position: absolute;
        top: 20px;
        left: 18px;
        width: 185px;
        height: 41px;
      }
    }
    .info {
      flex: 1 1 50%;
      padding: 100px 26px 66px 48px;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 2.5em;
        line-height: 1.1;
        color: var(--color-dark-blue);
        user-select: none;
      }

      .image{
        position: relative;
        flex: 1 1 auto;

        .phone {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 2;
          height: 59%;
        }

        .laptop {
          position: absolute;
          left: -40px;
          bottom: -60px;
          z-index: 1;
          height: 115%;
        }
      }
    }
  }
}

@media screen and (max-width: @max-width-sm) {
  .wrapper {
    background: var(--color-white);

    .container {
      .content {
        .logo {
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .info {
        display: none;
      }
    }
  }
}


@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;